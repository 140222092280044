.App {
  text-align: left;
}

.app-header {
  background-color: #75cee4;
  color: #f6f8e9;
  margin-bottom:20px;
}

.editor-container {
  border-color:  #2a3101;
  width: 90%;
  max-width: 800px;
}
.editor-header {
  height: 30px;
  background-color: #5bc8e4;
  color: #2a3101;
  text-align: center;
  font-weight: bold;
}
.about h1 {
  color: #049aa5;
}
.about h2 {
  color: #049aa5;
}
.about h3 {
  color: #049aa5;
}
.about h4 {
  color: #049aa5;
}
.about h5 {
  color: #049aa5;
}
.spinner-holder {
  width: 100%;
  text-align: center;
  padding: 10px;
}

.errline {
  font-style: italic;
}

.scrutin-status {
  text-align: left;
  padding: 10px;
}

.div-section {
  margin-top:10px;
}

.div-subsection {
  padding: 5px 0px;
}

.smaller-subsection {
  padding: 0 0 5px 10px;
  font-size: 90%;
  font-style: italic;
}

.strong-value {
  font-weight: bold;
}

.audit-voter {
  padding: 0 0 0 5px;
}
.audit-matchmail {
  font-weight: bold;
}
.audit-amail {
  font-weight: normal;
}
.audit-msg {
  margin: 3px;
  padding: 3px;
  border: 1px solid #049aa5;
  border-radius: 10px;
}
.audit-msg-field {
  padding: 0 2px;
}

.smaller {
  font-size: 90%;
  font-style: italic;
}

.boxed {
  padding: 5px;
  margin: 5px;
}

.tabcell {
  border: 1px solid black;
  padding: 6px;
  text-align: center;
}

.tab-green {
  color: green;
}
.tab-red {
  color:red;
}
.ed-header {
  background-color: #75cee4;
}
.ed-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0 0 0;
  border-top: 1px solid darkgray;
}
.ed-button {
  flex:1 0 auto;
}
.ed-error {
  padding-left: 10px;
  color:red;
  font-size: 85%;
}
.test-modify {
  width: 100%;
  padding-right: 15px;
  text-align: right;
}
.audit-div {
  width: 100%;
  margin-top: 15px;
  text-align: right;
}
.unsync-back {
  width: 100%;
  padding-right: 15px;
  text-align: right;
}
.drp {
  border:0;
  font-size:1rem;
  font-weight: 400;
  color:#495057;
  padding:0.375rem 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
}
.react-daterange-picker__wrapper {
  border:0;
  width:100%;
}
.react-daterange-picker__wrapper--bordered {
  border:1px solid #ced4da;
  border-radius:0.25rem;
  width:100%;
}
.react-daterange-picker__button {
  display: inherit;
}
.hourpicker-div {
  display:block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  font-size: 90%;
  line-height:1.5;
  color:#495057;
  background-color: white;
  background-clip: padding-box;
  border:1px solid #ced4da;
  border-radius: 0.25rem;
  transition:border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.hourpicker-select {
  display: inline;
  font-weight: 500;
  border: 1px solid #cccccc;
  font-size: 100%;
  line-height: 1.5;
  padding: 5px;
}