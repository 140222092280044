.sname {
  background-color: #e8eec8;
  text-align: center;
  padding: 15px;
  margin: 5px;
  border-radius: 1em;
}
.sorga {
  text-align:left;
  padding: 0 15px;
}

.btnicon {
  vertical-align: sub;
}

.btnlinkdiv {
  margin-bottom: 10px;
  text-align:center;
}

.testmailinfodiv {
  margin-top: 0;
  padding: 0 10px;
  text-align: center;
  font-style: italic;
}

.msgsent {
  font-family:sans-serif;
  font-size: 80%;
  color: darkslategray;
  font-style: italic;
}

.msgstatus {
  font-family:sans-serif;
  font-size: 80%;
  color: darkslategray;
}

.errfeedback {
  color:red;
  font-size:80%;
}

.scrutin_border {
  margin:10px 25px 25px 5px;
  background-color: aliceblue;
  padding:10px;
  border: 1px solid #049aa5;
  border-radius: 10px;
}

.voterrow {
  margin-bottom: 10px;
  background-color: #dbf5f7;
  padding: 7px 0;
}