.ed-nav-div {
  width: 100%;
  display: flex;                  /* Marque un Container flex. */
  flex-direction: row;            /* axe */
  flex-wrap: wrap;                /* Si wrappe quand contenu trop gros */
  justify-content: center;   /* Justif horizontale */
  align-items: baseline;           /* Alignement vertical */
  align-content: center;          /* Justif verticale si 2+ lignes */
  margin-bottom: 15px;
}
.ed-nav-tab {
  flex-grow: 1;
  flex-shrink: 0;
  text-align: center;
  
}
.ed-nav-chevron {
  flex-grow: 0;
  flex-shrink: 0;
  align-items: stretch;
}
.ed-neutral {
  background-color: rgb(164, 174, 185);
}
.ed-green {
  background-color:olivedrab;
  color:white;
}
.ed-red {
  background-color: darkred;
  color:white;
}
.ed-title {
  text-align: center;
  padding: 0 0 13px 0;
  font-weight: 700;
}
.ed-controls {
  width: 100%;
  display: flex;                  /* Marque un Container flex. */
  flex-direction: row;            /* axe */
  flex-wrap: wrap;                /* Si wrappe quand contenu trop gros */
  justify-content: space-between;        /* Justif horizontale */
  align-items: baseline;           /* Alignement vertical */
  align-content: center;          /* Justif verticale si 2+ lignes */
}
.ed-controls-btn {
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
}
.ed-control-actif {
  border: 3px solid darkolivegreen;
}
